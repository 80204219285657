#
# переменная состояния отладки/разработки
debug = true

#

$(document).ready ->
	# удалить рамку элементам
	remove_border_buttons()

	#
	return
# ==========================================


$(window).on 'load', ->
	# размеры landing cardsmenu
	landing_cardsmenu_dem()
	# делаем видимой страницу
	dense(main_ids().svera)
	#
	return
# ==========================================


$(window).on 'scroll', ->

	#
	return
# ==========================================

$(window).on 'resize', ->
	# задержка в ожидании конца события ресайза
	fnDelay1 (->
		#

		#
		return
	), 300
	#
	return
# ==========================================
